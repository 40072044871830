import { Toast } from "primereact/toast";
import { createContext, useContext, useState, useRef } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";

export const HabilidadesContext = createContext();
const HabilidadesContextProvider = ({ children }) => {
  const key = process.env.REACT_APP_TOKEN_KEY;
  const apiUrl = process.env.REACT_APP_URL_HABILIDADES;
  const toast = useRef(null);
  const navigate = useNavigate();
  const { setLoadGoogle, getTokenFromSessionStorage, setUsuario } =
    useContext(AuthContext);

  console.log("apiUrl", apiUrl, process.env.REACT_APP_URL_HABILIDADES);

  const [selectSkills, setSelectSkills] = useState([]);
  const [skillUser, setSkillUser] = useState([]);
  const [editSkill, setEditSkill] = useState(false);
  const [selectTecnologias, setSelectTecnologias] = useState([]);
  const [selectSubTecnologias, setSelectSubTecnologias] = useState([]);
  const [tecnologiaUser, setTenologiaUser] = useState([]);
  const [certificaciones, setCertificaciones] = useState([]);
  const [habilidadSeleccionada, setHabilidadSeleccionada] = useState("");
  const [selectNivelEstudio, setSelectNivelEstudio] = useState([]);
  const [nivelEstudioUser, setNivelEstudioUser] = useState([]);

  const getSkills = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + "/api/skillsComplementarias",
        options
      );

      if (response.ok) {
        const data = await response.json();
        setSelectSkills(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const postSkills = async (skill) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(skill),
      };
      const response = await fetch(apiUrl + "/api/skillsUsers", options);

      if (response.status === 201) {
        const data = await response.json();
        getSkillsUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se añadió tu skill",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al guardar la skill",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getSkillsUser = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/skillsUsers/user", options);

      if (response.ok) {
        const data = await response.json();
        setSkillUser(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const deleteSkills = async (skill) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(skill),
      };
      const response = await fetch(apiUrl + "/api/skillsUsers", options);

      if (response.status === 200) {
        const data = await response.json();
        getSkillsUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se elimino tu skill",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al eliminar la skill",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getTecnologias = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/technologies", options);

      if (response.ok) {
        const data = await response.json();
        setSelectTecnologias(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const getSubTecnologias = async (id) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + "/api/subtechnologies/" + id,
        options
      );

      if (response.ok) {
        const data = await response.json();
        setSelectSubTecnologias(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const getTecnologiasUser = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + "/api/technologiesUsers/user",
        options
      );

      if (response.ok) {
        const data = await response.json();
        setTenologiaUser(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const postTenologias = async (skill) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(skill),
      };
      const response = await fetch(apiUrl + "/api/technologiesUsers", options);

      if (response.status === 201) {
        const data = await response.json();
        getTecnologiasUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se añadió tus tecnologias",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al guardar las tecnologias",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const putTecnologias = async (skill) => {
    try {
      const { id, ...bodyWithoutId } = skill;
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyWithoutId),
      };
      const response = await fetch(
        apiUrl + "/api/technologiesUsers/" + skill.id,
        options
      );

      if (response.status === 200) {
        const data = await response.json();
        getTecnologiasUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se actualizalo la tecnologia",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al actualizar la tecnologia",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const deleteTecnologias = async (id) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + "/api/technologiesUsers/" + id,
        options
      );

      if (response.status === 200) {
        const data = await response.json();
        getTecnologiasUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se elimino tu tecnologia",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al eliminar la tecnologia",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getCertificaciones = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/certificates", options);

      if (response.ok) {
        const data = await response.json();
        setCertificaciones(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const postCertificaciones = async (skill) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const formData = new FormData();
      formData.append("certificationTitle", skill.certificationTitle);
      formData.append("institutionName", skill.institutionName);

      if (skill.certificacionFile) {
        formData.append("certificationFile", skill.certificacionFile);
      }

      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      };

      const response = await fetch(apiUrl + "/api/certificates", options);

      if (response.status === 201) {
        const data = await response.json();
        getCertificaciones();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se añadió tu Certificación",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al guardar la certificación",
          life: 5000,
        });
        return [];
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const putCertificaciones = async (skill) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const formData = new FormData();
      formData.append("certificationTitle", skill.certificationTitle);
      formData.append("institutionName", skill.institutionName);

      if (skill.certificacionFile) {
        formData.append("certificationFile", skill.certificacionFile);
      }

      const options = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      };

      const response = await fetch(
        apiUrl + "/api/certificates/" + skill.id,
        options
      );

      if (response.status === 200) {
        const data = await response.json();
        getCertificaciones();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se actualizo tu Certificación",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al actualizar la certificación",
          life: 5000,
        });
        return [];
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const deleteCertificaciones = async (id) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/certificates/" + id, options);

      if (response.status === 200) {
        const data = await response.json();
        getCertificaciones();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se elimino tu certificación",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al eliminar la certificación",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getNivelEstudios = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/educationLevel", options);

      if (response.ok) {
        const data = await response.json();
        setSelectNivelEstudio(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const postNivelEstudios = async (skill) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(skill),
      };
      const response = await fetch(apiUrl + "/api/educationUser", options);

      if (response.status === 201) {
        const data = await response.json();
        getNivelEstudioUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se añadió tu nivel de estudio",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al guardar el nivel de estudio",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getNivelEstudioUser = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/educationUser", options);

      if (response.ok) {
        const data = await response.json();
        setNivelEstudioUser(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const putNivelEstudios = async (skill) => {
    try {
      const { id, ...bodyWithoutId } = skill;
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "PuT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyWithoutId),
      };
      const response = await fetch(
        apiUrl + "/api/educationUser/" + skill.id,
        options
      );

      if (response.status === 200) {
        const data = await response.json();
        getNivelEstudioUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se actualizalo tu nivel de estudio",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al actualizar el nivel de estudio",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const deleteNivelEstudio = async (id) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + "/api/educationUser/" + id,
        options
      );

      if (response.status === 200) {
        const data = await response.json();
        getNivelEstudioUser();
        setEditSkill(false);
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Se elimino tu nivel de estudio",
          life: 5000,
        });
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al eliminar el nivel de estudio",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };
  return (
    <HabilidadesContext.Provider
      value={{
        selectSkills,
        getSkills,
        postSkills,
        getSkillsUser,
        skillUser,
        editSkill,
        setEditSkill,
        deleteSkills,
        getTecnologias,
        selectTecnologias,
        getSubTecnologias,
        selectSubTecnologias,
        getCertificaciones,
        certificaciones,
        postCertificaciones,
        putCertificaciones,
        habilidadSeleccionada,
        setHabilidadSeleccionada,
        deleteCertificaciones,
        getNivelEstudios,
        selectNivelEstudio,
        postNivelEstudios,
        nivelEstudioUser,
        getNivelEstudioUser,
        putNivelEstudios,
        deleteNivelEstudio,
        getTecnologiasUser,
        tecnologiaUser,
        postTenologias,
        deleteTecnologias,
        putTecnologias,
      }}
    >
      {children}
      <Toast ref={toast} />
    </HabilidadesContext.Provider>
  );
};

export default HabilidadesContextProvider;
