import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import { DialogExportExcel } from "./DialogExportExcel";
import { AuthContext } from "../../contexts/AuthContext";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import CecosTable from "./CecosTable";
import Menu from "../../components/Menu";
import RecursosTable from "./RecursosTable";
import { EXCEL_TYPES } from "./types";
import logoitp from "../../imagenes/logo.png";
import "./informes.css";

const dropOptions = [
  { label: "Recursos", id: 0 },
  { label: "Cecos", id: 1 },
];

const Informes = () => {
  const { getRecursos, recursosArray, getCecos, cecos } =
    useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const [listadoOpt, setListadoOpt] = useState(dropOptions[0]);
  const [xlsDialogVis, setXlsDialogVis] = useState(false);
  const [xlsTipo, setXlsTipo] = useState(0);

  useEffect(() => {
    getRecursos();
    getCecos();
  }, []);

  const speedDialItems = [
    {
      label: "Informe Exportable Clientes",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.clientes);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "admin",
    },
    {
      label: "Informe Exportable Proveedores",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.proveedores);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "admin",
    },
    {
      label: "Informe Exportable PO",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.po);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "po",
    },
    {
      label: "Informe Recursos",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.recursos);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "po" || usuario.Rol === "admin",
    },
  ];

  return (
    <div className="container">
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades" style={{ marginBottom: "20px" }}>
        <div>
          <h3 className="">Informes y consultas</h3>
          {usuario.Rol !== "" && (
            <div className="speeddial-tooltip-demo">
              <Tooltip
                target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action"
                position="top"
              />
              <SpeedDial
                className="speed_dial speeddial-right"
                model={speedDialItems}
                direction="right"
              />
            </div>
          )}
        </div>
      </div>
      <DialogExportExcel
        usuario={usuario}
        xlsTipo={xlsTipo}
        opciones={xlsTipo !== EXCEL_TYPES.recursos}
        xlsDialogVis={xlsDialogVis}
        setXlsDialogVis={setXlsDialogVis}
      />
      <div className="input-container div_dropdown-informes">
        <span className="p-float-label">
          <Dropdown
            inputId="option"
            name="option"
            className="dropdown-informes"
            value={listadoOpt}
            onChange={(e) => setListadoOpt(e.value)}
            options={dropOptions}
            optionLabel="label"
          />
          <label htmlFor="option" style={{ fontSize: "13px" }}>
            Seleccione una lista
          </label>
        </span>
      </div>
      {listadoOpt.id === 0 && <RecursosTable dataValues={recursosArray} />}
      {listadoOpt.id === 1 && <CecosTable dataValues={cecos} />}
    </div>
  );
};

export default Informes;
